/*eslint-disable no-useless-escape*/
import Vue from 'vue';
import Vuex from 'vuex';
import { scrollTo } from 'vue-scrollto';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    step: 1,
    isLoading: false,
    modalHotline: false,
    checked: 0,

    user: {
      expressscheidung: '',
      zahlung: '',
      antragsteller: '',
      zustimmung: '',
      vorname1: '',
      nachname1: '',
      strasse1: '',
      ort1: '',
      staat1: '',
      netto1: '',
      vorname2: '',
      nachname2: '',
      strasse2: '',
      ort2: '',
      staat2: '',
      netto2: '',
      datum_heirat: '',
      getrennt: '',
      ort_heirat: '',
      kinder: '',
      strasse3: '',
      ort3: '',
      wohnung_bleiben: '',
      ehegattenunterhalt: '',
      name: '',
      firstname: '',
      email: '',
      phone: '',
      id:'',
      casenumber:'',
      refCode:'',
      convCode:''
    },
  },
  getters: {
    userData: (state) => state.user,
    isLoading: (state) => state.isLoading,
    modalHotline: (state) => state.modalHotline,
    step: (state) => state.step
  },
  
  mutations: {
    updateUser(state, payload) {
      state.user.id = payload;
      state.isLoading = false;
    },
    updateUserFormHusband(state, payload) {
      state.user.vorname1 = payload.firstname;
      state.user.nachname1 = payload.name;
      state.user.strasse1 = payload.streetNo;
      state.user.ort1 = payload.postcodeCity;
      state.user.staat1 = payload.nationality;
      state.user.netto1 = payload.monthlyIncome;
    },
    updateUserFormWife(state, payload) {
      state.user.vorname2 = payload.firstname;
      state.user.nachname2 = payload.name;
      state.user.strasse2 = payload.streetNo;
      state.user.ort2 = payload.postcodeCity;
      state.user.staat2 = payload.nationality;
      state.user.netto2 = payload.monthlyIncome;
    },
    resetUser(state) {
      state.user = {
        expressscheidung: '',
        zahlung: '',
        antragsteller: '',
        zustimmung: '',
        vorname1: '',
        nachname1: '',
        strasse1: '',
        ort1: '',
        staat1: '',
        netto1: '',
        vorname2: '',
        nachname2: '',
        strasse2: '',
        ort2: '',
        staat2: '',
        netto2: '',
        datum_heirat: '',
        getrennt: '',
        ort_heirat: '',
        kinder: '',
        strasse3: '',
        ort3: '',
        wohnung_bleiben: '',
        ehegattenunterhalt: '',
        name: '',
        firstname: '',
        email: '',
        phone: '',
        id:'',
        casenumber:'',
        refCode:'',
        convCode:''
      };
    },
    updatePhoneNumber(state, payload) {
      state.user.phone = payload;
    },
    updateRefCode(state, payload){
      state.user.refCode = payload;
    },
    updateConvCode(state, payload){
      state.user.convCode = payload;
    },
    setLoading(state, payload) {
      state.isLoading = payload;
    },
    setModalHotline(state) {
      state.modalHotline = !state.modalHotline;
    },
    setStep(state, payload) {
      state.step = payload;
    },
    incrementStep (state) {
      // mutate state
      state.step++;
    },
    decrementStep (state) {
      // mutate state
      state.step--;
    },
    setChecked(state, payload) {
      state.checked = payload;
    },
    incrementChecked (state) {
      // mutate state
      state.checked++;
      if (state.step == 1){
        scrollTo('.form-control', 500, { easing: 'ease-in-out' });
      }

    },
    decrementChecked (state) {
      // mutate state
      state.checked--;
    }
  },
  actions: {
    setLoadingState({ commit }, showLoading) {
      commit('setLoading', showLoading);
    },
    setRefCode({ commit }, refCode) {
      commit('updateRefCode', refCode);
    },
    setConvCode({ commit }, convCode) {
      commit('updateConvCode', convCode);
    }
  },
  modules: {
  },
});
